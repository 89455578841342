import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import favicon from '../../images/brand_icon.png'

interface Props {
	title: string
	description?: string
	keywords?: string
	pathName: string
}

interface SiteMetaData {
	site: {
		siteMetadata: {
			title: string
			description: string
			author: string
			keywords: string
			siteUrl: string
		}
	}
}

export default ({ title, description, keywords, pathName }: Props) => {
	const { site } = useStaticQuery<SiteMetaData>(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						keywords
						siteUrl
					}
				}
			}
		`
	)

	const siteDescription = description || site.siteMetadata.description

	return (
		<Helmet
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: 'description',
					content: siteDescription
				},
				{
					name: 'keywords',
					content: keywords || site.siteMetadata.keywords
				},
				{
					property: 'og:title',
					content: title
				},
				{
					property: 'og:description',
					content: siteDescription
				},
				{
					property: 'og:type',
					content: 'website'
				},
				{
					property: 'og:url',
					content: `${site.siteMetadata.siteUrl}${pathName}`,
					id: 'og-url'
				},
				{
					property: 'og:site_name',
					content: 'Spektro Servisas'
				},
				// {
				// 	property: 'og:image',
				// 	content: OgImage
				// },
				{
					property: 'og:image:alt',
					content: 'Spektro Servisas logo'
				},
				{
					property: 'og:image:type',
					content: 'image/png'
				},
				{
					property: 'og:image:width',
					content: '1200'
				},
				{
					property: 'og:image:height',
					content: '630'
				},
				{
					name: 'twitter:card',
					content: 'summary_large_image'
				},
				// {
				// 	name: 'twitter:site',
				// 	content: '@bitrune'
				// },
				// {
				// 	name: 'twitter:image',
				// 	content: TwitterImage
				// },
				{
					property: 'twitter:image:alt',
					content: 'Spektro servisas logo'
				},
				{
					name: 'twitter:image:width',
					content: '626'
				},
				{
					name: 'twitter:image:height',
					content: '335'
				},
				{
					name: 'twitter:description',
					content: description
				}
			]}
			link={[
				{
					rel: 'shortcut icon',
					type: 'image/png',
					href: favicon
				}
			]}
		>
			<script type='application/ld+json'>
				{JSON.stringify({
					'@context': 'https://schema.org/',
					'@type': 'Organization',
					name: 'Spektro servisas',
					url: 'https://www.spektroservisas.lt/',
					image: favicon,
					description:
						'Daugiau nei 20 metų patirties turinti komanda užtikrins kokybišką Jūsų transporto priemonės priežūrą ir remontą. Mūsų darbuotajai yra labai dėmesingi – tai užtikrina, kad ir menkiausi gedimai bus pastebėti ir sutvarkyti.',
					contactPoint: {
						'@type': 'ContactPoint',
						contactType: 'administration',
						email: 'servisas@spektroservisas.lt',
						url: 'https://www.spektroservisas.lt/contacts',
						phone: '+370 650 90303'
					}
				})}
			</script>
		</Helmet>
	)
}
